import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { del } from '../../actions/order/delete';
import { reset, retrieve } from '../../actions/order/show';
import { formatTotalToDollars } from '../../utils/ecommerce';
import SecureImage from '../SecureImage';

class Show extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),

    reset: PropTypes.func.isRequired,
    retrieve: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    return (
      <div id="order-confirmation">
        <Row>
          <Col
            className="mt-5"
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{
              span: 7
            }}
          >
            <div
              className="d-flex text-center"
              style={{ justifyContent: 'center' }}
            >
              <div
                className={'card bg-white text-center border-0 d-flex'}
                style={{ width: '50%' }}
              >
                <div className="bulb"></div>
                <h1
                  id="post-checkout-thank-you-message"
                  className="font-weight-bold"
                >
                  Thank You for Your Purchase!
                </h1>
                <p className="mt-2 lh-lg">
                  You can view your new templates in the "Templates" tab of your
                  navigation bar, or click below to get started straight away
                </p>
                <Link
                  to="/procedures/"
                  className={'btn btn-cta create-btn mt-4'}
                  style={{ width: '70%', alignSelf: 'center' }}
                >
                  View Your Templates
                </Link>
              </div>
            </div>
          </Col>
          <Col
            className="mb-5"
            sm={{ span: 10, offset: 2 }}
            md={{ span: 10, offset: 2 }}
            lg={{
              span: 5,
              offset: 0
            }}
          >
            <div
              className="card bg-light mt-4 px-4 pb-5"
              style={{
                width: '80%',
                border: '4px solid lightgrey',
                minHeight: '90vh'
              }}
            >
              <h4 className="ml-2 mt-5 mb-1 font-weight-bold">Order Summary</h4>
              <hr className="ml-2 mr-2 mt-2" />
              {this.props.retrieved &&
                this.props.retrieved.orderItems.map((item, index) => (
                  <div
                    className={'d-flex my-5 px-4'}
                    key={`cart-item-${index}`}
                    style={{ justifyContent: 'center' }}
                  >
                    <SecureImage
                      file={item?.procedureFile}
                      zoom={false}
                      alt={'Upload Preview Image'}
                      id={'purchasable-procedure-file' + item.procedureFile.id}
                      attributes={{
                        width: 75,
                        style: { cursor: 'pointer' }
                      }}
                    />
                    <div className="ml-3 d-flex">
                      <h6
                        className="mb-0 font-weight-bold"
                        style={{ alignSelf: 'center' }}
                      >
                        {item.procedureTitle}
                      </h6>
                    </div>
                    <div className="ml-auto d-flex">
                      <h6 className="mb-0" style={{ alignSelf: 'center' }}>
                        ${item.price}
                      </h6>
                    </div>
                  </div>
                ))}
              <hr className="mx-2 mb-3" />
              {this.props.retrieved && (
                <>
                  <div className="d-flex my-2 px-4">
                    <p>Subtotal</p>
                    <h6 className="ml-auto">
                      {formatTotalToDollars(
                        this.props.retrieved.orderTotals.subTotal
                      )}
                    </h6>
                  </div>
                  <hr className="mx-2 my-2" />
                  <div className="d-flex mt-3  mb-5 px-4">
                    <h5 className="font-weight-bold">Total</h5>
                    <h4 className="total font-weight-bold ml-auto">
                      {formatTotalToDollars(
                        this.props.retrieved.orderTotals.grandTotal
                      )}
                    </h4>
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.order.show.retrieved,
  error: state.order.show.error,
  loading: state.order.show.loading,
  eventSource: state.order.show.eventSource
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(Show);
