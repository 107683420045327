import mediumZoom from 'medium-zoom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { connect } from 'react-redux';
import { change as changeFormField } from 'redux-form';

import { create as createCart } from '../../actions/cart/create';
import { list as cart } from '../../actions/cart/list';
import { create as createCartItem } from '../../actions/cartitem/create';
import { del as deleteCartItem } from '../../actions/cartitem/delete';
import { list, reset } from '../../actions/purchasableprocedure/list';
import imageUpload from '../../assets/img/temp-upload-image.png';
import { getCurrentUserId } from '../../utils/auth';
import { emptyCartTooltipOverlay } from '../../utils/ecommerce';
import { formatTotalToDollars } from '../../utils/ecommerce';
import {
  filterSerialize,
  getFilterValues,
  getPageWithFilterValues,
  setFilterValues
} from '../../utils/listFilter';
import { getCurrentOrganizationForAccount } from '../../utils/subscription';
import CategoryTreeSelectionForm from '../category/UpdatePurchasableCategoryTree';
import SecureImage from '../SecureImage';
import { VersionLink as Link } from '../VersionLink';
import FilterForm from './FilterForm';

class List extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    retrievedCart: PropTypes.object,
    account: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    loadingCart: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    cart: PropTypes.func.isRequired,
    createCart: PropTypes.func.isRequired,
    createCartItem: PropTypes.func.isRequired,
    deleteCartItem: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  state = {
    activeOrderedColumn: { columnName: '', direction: 'asc' },
    batchAddPurchasableProceduresIds: []
  };

  componentDidMount() {
    this.props.list(
      this.getPageWithFilterValues(
        this.props.match.params.page &&
          decodeURIComponent(this.props.match.params.page)
      )
    );

    this.props.cart('carts', this.props.account.show.retrieved);
    this.instantiateZoom();
  }

  instantiateZoom = () => {
    if (this.zoom) {
      this.zoom.detach();
    }
    this.zoom = mediumZoom('img', {
      background: 'rgba(0,0,0,0)'
      // container: '#zoom-container',
      // template: '#zoom-template',
    });

    this.zoom.on('opened', () => {
      const closeButton = document.querySelector('[data-zoom-close]');
      closeButton.addEventListener('click', () => this.zoom.close());
    });

    // Block scroll on zoom
    this.zoom.on('open', () => {
      document.body.style.overflow = 'hidden';
    });

    this.zoom.on('close', () => {
      document.body.style.overflow = '';
    });
  };

  doImageClick = id => {
    // Click the image to toggle zoom
    const image = document.getElementById(`purchasable-procedure-file-${id}`);
    if (image) {
      image.click();
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        this.getPageWithFilterValues(
          nextProps.match.params.page &&
            decodeURIComponent(nextProps.match.params.page)
        )
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
    this.zoom.detach();
  }

  selectRootItem = (event, data, component) => {
    this.props.changeFormField(
      'purchasableProcedureFilter',
      'procedure.category.id',
      ''
    );

    setFilterValues('lastPurchasableCategory', {});

    component.getTreeComponent().getTreeComponent().resetSelectedTreeItem();

    setTimeout(() => {
      component.getTreeComponent().getTreeComponent().clearSelectedTreeItems();
      component.reapplySelectedTreeRootItem();

      let submitButton = document.getElementById('hidden-submit-filter-button');
      if (submitButton) {
        submitButton.click(); // handleBlur
      }

      component.getTreeComponent().getTreeComponent().clearSelectedTreeItems();
      component.reapplySelectedTreeRootItem();
    }, 1000);
  };

  selectTreeItem = (data, index, component) => {
    this.props.changeFormField(
      'purchasableProcedureFilter',
      'procedure.category.id',
      data['@id']
    );

    setFilterValues('lastPurchasableCategory', data);

    setTimeout(() => {
      let submitButton = document.getElementById('hidden-submit-filter-button');
      if (submitButton) {
        submitButton.click(); // handleBlur
      }
    }, 100);
  };

  pressFormReset = () => {
    this.setFilterValues({});
    this.localClearSelectedTreeItems();
    this.localReapplySelectedTreeRootItem();

    setFilterValues('lastPurchasableCategory', {});

    this.props.list(
      this.getPageWithFilterValues(
        this.props.match.params.page &&
          decodeURIComponent(this.props.match.params.page)
      )
    );

    setTimeout(() => {
      this.localClearSelectedTreeItems();
      this.localReapplySelectedTreeRootItem();
    }, 1000);
  };

  // @see TreeFormFields.clearSelectedTreeItems
  localClearSelectedTreeItems = () => {
    // clear existing selected classes
    let selectedElems = document.getElementsByClassName('selected-item');
    Object.keys(selectedElems).map(seIndex => {
      if (!!selectedElems[seIndex]) {
        selectedElems[seIndex].className = selectedElems[
          seIndex
        ].className.replace('selected-item', '');
      }
      return seIndex;
    });
    let selectedParents = document.getElementsByClassName(
      'selected-item-parent'
    );
    Object.keys(selectedParents).map(spIndex => {
      if (!!selectedParents[spIndex]) {
        selectedParents[spIndex].className = selectedParents[
          spIndex
        ].className.replace('selected-item-parent', '');
      }
      return spIndex;
    });
  };

  // @see TreeForm.reapplySelectedTreeRootItem
  localReapplySelectedTreeRootItem = () => {
    // re-apply selected classes
    let selectedEls = document.getElementsByName('name');
    if (selectedEls.length > 0 && selectedEls[0]) {
      let selectedEl = selectedEls[0];
      if (selectedEl.parentNode.className.indexOf('selected-item') === -1) {
        selectedEl.parentNode.className += ' selected-item';
      }
      // input / form-group / tree-root-item
      let parentEl = selectedEl.parentNode.parentNode;
      if (parentEl.className.indexOf('selected-item-parent') === -1) {
        parentEl.className += ' selected-item-parent';
      }
    }
  };

  getSortableHeader = (field, startDirection) => {
    let page = decodeURIComponent(
      this.getPageWithFilterValues(
        this.props.retrieved['hydra:view']
          ? this.props.retrieved['hydra:view']['@id']
          : '/purchasable_procedures'
      )
    );

    let queryString = field + '=';
    if (page.indexOf(queryString) === -1) {
      if (page.indexOf('order[') !== -1) {
        page = page.replace(/[?&]order[[][^\]]+[\]]=(asc|desc)/, '');
      }
      page =
        page +
        (page.indexOf('?') === -1 ? '?' : '&') +
        queryString +
        startDirection;
    } else {
      // provided field is being used to sort
      let queryStringAsc = field + '=asc';
      let queryStringDesc = field + '=desc';
      if (page.indexOf(queryStringAsc) !== -1) {
        this.setActiveColumn(field, 'asc');

        if (page.indexOf('order[') !== -1) {
          page = page.replace(/[?&]order[[][^\]]+[\]]=(asc|desc)/, '');
        }
        page = page + (page.indexOf('?') === -1 ? '?' : '&') + queryStringDesc;
      } else if (page.indexOf(queryStringDesc) !== -1) {
        this.setActiveColumn(field, 'desc');

        if (page.indexOf('order[') !== -1) {
          page = page.replace(/[?&]order[[][^\]]+[\]]=(asc|desc)/, '');
        }
        page = page + (page.indexOf('?') === -1 ? '?' : '&') + queryStringAsc;
      }
    }

    return encodeURIComponent(page);
  };

  getPageWithFilterValues = page => {
    return getPageWithFilterValues(
      'purchasableProcedureFilterValues',
      page,
      '/purchasable_procedures'
    );
  };

  getFilterValues = () => {
    return getFilterValues('purchasableProcedureFilterValues');
  };

  setFilterValues = values => {
    setFilterValues('purchasableProcedureFilterValues', values);
  };

  isFiltered = () => {
    let filtered = this.getFilterValues();

    if (
      !!filtered.search_text ||
      !!filtered.name ||
      !!filtered.author ||
      !!(filtered.category && filtered.category.id) ||
      !!filtered.is_my_procedure
    ) {
      return true;
    }

    return false;
  };

  serialize = (obj, prefix) => {
    return filterSerialize(obj, prefix, (prefix, p) => {
      if (prefix.indexOf('tags') !== -1) {
        return false;
      }
      return true;
    });
  };

  filter = (filtered, values) => {
    this.setFilterValues(values);

    let queryString = this.serialize(values);

    this.props.list('/purchasable_procedures?' + queryString);
  };

  setActiveColumn = (field, direction) => {
    if (
      (this.state.activeOrderedColumn &&
        this.state.activeOrderedColumn.columnName !== field) ||
      (this.state.activeOrderedColumn &&
        this.state.activeOrderedColumn.direction !== direction)
    ) {
      this.setState({
        activeOrderedColumn: { columnName: field, direction: direction }
      });
    }
  };

  getOrderedCaret = () => {
    const direction =
      this.state.activeOrderedColumn.direction === 'asc' ? 'up' : 'down';
    const caretClass = `ml-2 fa fa-caret-${direction}`;
    return <i className={caretClass} aria-hidden="true"></i>;
  };

  showRenewalPrice = (lastUpdateDate, lastPurchasedDate) => {
    if (!lastPurchasedDate) {
      return false;
    }
    const lastPurchase = Date.parse(lastPurchasedDate);
    const lastUpdate = Date.parse(lastUpdateDate);

    return lastPurchase < lastUpdate;
  };

  addOrRemoveFromBatch(id) {
    let newBatch = this.state.batchAddPurchasableProceduresIds;
    if (newBatch.includes(id)) {
      const index = newBatch.indexOf(id);
      if (index !== -1) {
        newBatch.splice(index, 1);
      }
    } else {
      newBatch.push(id);
    }

    this.setState(prevState => {
      return {
        ...prevState,
        batchAddPurchasableProceduresIds: newBatch
      };
    });
  }

  handleAddToCart = async purchasableProcedureIds => {
    const cartId = await this.getOrCreateCart();

    for (const purchasableProcedureId of purchasableProcedureIds) {
      if (this.isItemInCart(purchasableProcedureId)) {
        continue;
      }

      await this.props.createCartItem({
        cart: cartId,
        purchasableProcedure: purchasableProcedureId
      });
    }
    let response = await this.props.cart(
      '/carts',
      this.props.account.show.retrieved
    );

    this.setState(prevState => {
      return {
        ...prevState,
        cart: response
      };
    });
  };

  handleRemoveFromCart = async item => {
    const cartItemsArray =
      this.props.retrievedCart &&
      this.props.retrievedCart['hydra:member'].length > 0 &&
      this.props.retrievedCart['hydra:member'][0].cartItems;

    const index = cartItemsArray
      .map(function (cartItem) {
        return cartItem.purchasableProcedure['@id'];
      })
      .indexOf(item['@id']);
    if (index === -1) {
      return;
    }

    const cartItem = cartItemsArray[index];
    await this.props.deleteCartItem(cartItem);

    let response = this.props.cart('/carts', this.props.account.show.retrieved);

    this.setState(prevState => {
      return {
        ...prevState,
        cart: response
      };
    });
  };

  getOrCreateCart = async () => {
    if (
      this.props.retrievedCart &&
      this.props.retrievedCart['hydra:member'].length > 0
    ) {
      return this.props.retrievedCart['hydra:member'][0]['@id'];
    } else {
      const userId = getCurrentUserId();
      const organization = getCurrentOrganizationForAccount(
        this.props.account.show.retrieved
      );

      let response = await this.props.createCart({
        customer: userId,
        organization: organization['@id']
      });

      this.props.cart('carts', this.props.account.show.retrieved); // Update cart object in redux store
      return response.created['@id'];
    }
  };

  isItemInCart = procedureId => {
    const cartItemsArray =
      this.props.retrievedCart &&
      this.props.retrievedCart['hydra:member'].length > 0 &&
      this.props.retrievedCart['hydra:member'][0].cartItems;

    if (!cartItemsArray) {
      return false;
    }

    return cartItemsArray.some(item => {
      return item.purchasableProcedure['@id'] === procedureId;
    });
  };

  getCartSize = () => {
    return this.props.retrievedCart &&
      this.props.retrievedCart['hydra:member'] &&
      this.props.retrievedCart['hydra:member'].length > 0
      ? this.props.retrievedCart['hydra:member'][0].cartItems.length
      : 0;
  };

  render() {
    let lastSelectedCategoryId = false;
    let filtered = this.getFilterValues();
    if (filtered && filtered.category && filtered.category.id) {
      lastSelectedCategoryId = filtered.category.id.replace(/[^\d]*/, '');
    }

    let lastProcedureCategory = '';
    let lastProcedureCategoryData = getFilterValues('lastPurchasableCategory');
    if (lastProcedureCategoryData && lastProcedureCategoryData.name) {
      lastProcedureCategory = ' in ' + lastProcedureCategoryData.name;
    }

    return (
      <>
        <template id="zoom-template">
          <div className="zoom-wrapper">
            <div className="zoom-close" data-zoom-close>
              X
            </div>
            <div id="zoom-container"></div>
            <section className="zoom-main" data-zoom-container></section>
          </div>
        </template>

        <div className={'page-list page-procedure-list mt-3'}>
          {/*{this.props.loading && (*/}
          {/*  <div className="alert alert-info">Loading...</div>*/}
          {/*)}*/}
          {this.props.loading && (
            <div className="pull-right" role="status">
              <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', top: 0, left: 0 }}>
                  Loading...
                </div>
              </div>
            </div>
          )}
          {this.props.deletedItem && (
            <div className="alert alert-success">
              {this.props.deletedItem['@id']} deleted.
            </div>
          )}
          {this.props.error && (
            <div className="alert alert-danger">{this.props.error}</div>
          )}

          <div className={'procedureTemplateListContainer row ml-0 mr-0'}>
            <div className={'col-md-3 ml-0 pl-0 mr-0 pr-0'}>
              <CategoryTreeSelectionForm
                isDraggingEnabled={false}
                displayAddButton={false}
                displayEditItemButton={'not_editable'}
                displayDeleteItemButton={false}
                displaySelectItemButton={false}
                lastSelectedFieldIndex={lastSelectedCategoryId}
                selectRootItem={this.selectRootItem}
                selectTreeItem={this.selectTreeItem}
                trackSelectTreeItem={true}
                allowEnterKey={false}
                dragOverTreeItem={this.dragend}
                dropTreeItem={this.drop}
              />
            </div>
            <div className={'col-md-9 mr-0 pr-0 ml-0 pl-0'}>
              <div className={'mt-2 mb-2 ml-2 mr-2'}>
                <div className={'filter-form'}>
                  <FilterForm
                    onSubmit={values => this.filter(filtered, values)}
                    initialValues={filtered}
                    pressFormSubmit={this.pressFormSubmit}
                    pressFormReset={this.pressFormReset}
                    displayAuthorFilter={false}
                    displayResetSearch={false}
                  />
                  <div className={'flex flex-row gap'}>
                    {this.getCartSize() > 0 ? (
                      <Link to={'/carts/'} className={'text-decoration-none'}>
                        <button className={'btn-action btn-view-cart'}>
                          <i className="fa fa-shopping-cart"></i>
                          <span>View Cart ({this.getCartSize()})</span>
                        </button>
                      </Link>
                    ) : (
                      <div>
                        <OverlayTrigger
                          trigger="hover"
                          placement="top"
                          overlay={emptyCartTooltipOverlay}
                        >
                          <button className={'btn-action btn-view-cart'}>
                            <i className="fa fa-shopping-cart"></i>
                            <span>View Cart ({this.getCartSize()})</span>
                          </button>
                        </OverlayTrigger>
                      </div>
                    )}

                    {this.getCartSize() > 0 ? (
                      <Link to="/checkout" className={'text-decoration-none'}>
                        <button className={'btn-action'}>
                          <i className="fa fa-credit-card"></i>
                          <span>Checkout</span>
                        </button>
                      </Link>
                    ) : (
                      <div>
                        <OverlayTrigger
                          trigger="hover"
                          placement="top"
                          overlay={emptyCartTooltipOverlay}
                        >
                          <button className={'btn-action'}>
                            <i className="fa fa-credit-card"></i>
                            <span>Checkout</span>
                          </button>
                        </OverlayTrigger>
                      </div>
                    )}
                  </div>
                </div>
                {this.props.retrieved &&
                this.props.retrieved['hydra:member'] &&
                this.props.retrieved['hydra:member'].length > 0 ? (
                  <div className="procedure-list-table-wrapper">
                    <table className="table table-responsive table-striped table-hover">
                      <thead>
                        <tr>
                          <th></th>
                          <th>
                            <Link
                              to={this.getSortableHeader(
                                'order[procedure.name]',
                                'asc'
                              )}
                              onClick={() =>
                                this.setActiveColumn('order[procedure.name]')
                              }
                            >
                              Title
                              {this.state.activeOrderedColumn &&
                                this.state.activeOrderedColumn.columnName ===
                                  'order[procedure.name]' &&
                                this.getOrderedCaret()}
                            </Link>
                          </th>
                          {/*<th>*/}
                          {/*  <Link*/}
                          {/*    to={this.getSortableHeader(*/}
                          {/*      'order[procedure.category.name]',*/}
                          {/*      'asc'*/}
                          {/*    )}*/}
                          {/*  >*/}
                          {/*    Category*/}
                          {/*    {this.state.activeOrderedColumn &&*/}
                          {/*      this.state.activeOrderedColumn.columnName ===*/}
                          {/*        'order[procedure.category.name]' &&*/}
                          {/*      this.getOrderedCaret()}*/}
                          {/*  </Link>*/}
                          {/*</th>*/}
                          <th>
                            <Link
                              to={this.getSortableHeader(
                                'order[procedure.updatedAt]',
                                'asc'
                              )}
                            >
                              Last Updated
                              {this.state.activeOrderedColumn &&
                                this.state.activeOrderedColumn.columnName ===
                                  'order[procedure.updatedAt]' &&
                                this.getOrderedCaret()}
                            </Link>
                          </th>
                          <th>Price</th>
                          <th colSpan={2}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.retrieved &&
                          this.props.retrieved['hydra:member'].map(item => {
                            const isItemInCart = this.isItemInCart(item['@id']);

                            return (
                              <tr>
                                <td>
                                  <label class={'checkbox-container'}>
                                    <input
                                      type="checkbox"
                                      name="key"
                                      value="value"
                                    />
                                    <span
                                      className="checkmark"
                                      onClick={e =>
                                        this.addOrRemoveFromBatch(item['@id'])
                                      }
                                    ></span>
                                  </label>
                                </td>
                                <th
                                  scope="row"
                                  key={item['@id']}
                                  draggable={false}
                                  onDragStart={() => {}}
                                  id={item['@id']}
                                >
                                  {item.purchasableProcedureFile && (
                                    <SecureImage
                                      file={item.purchasableProcedureFile}
                                      zoom={this.zoom}
                                      alt={'Preview Image'}
                                      src={imageUpload}
                                      id={`purchasable-procedure-file-${item.purchasableProcedureFile.token}`}
                                      attributes={{
                                        width: 30,
                                        // height: 30,
                                        style: { cursor: 'pointer' }
                                      }}
                                      border={true}
                                    />
                                  )}
                                  <span
                                    role="button"
                                    onClick={() =>
                                      this.doImageClick(
                                        item.purchasableProcedureFile.token
                                      )
                                    }
                                  >
                                    {item['procedure']['name']}
                                  </span>
                                </th>
                                <td>
                                  <div>
                                    {item['procedure']['updatedAtDate']}
                                  </div>
                                  {item['latestLastPurchasedDate'] && (
                                    <div className={'subscript text-muted'}>
                                      You purchased on{' '}
                                      {item['latestLastPurchasedDate']}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {item['latestLastPurchasedDate'] ? (
                                    <span>
                                      {formatTotalToDollars(
                                        item['renewalDiscountPrice']
                                      )}
                                    </span>
                                  ) : (
                                    <span>
                                      {formatTotalToDollars(item['price'])}
                                    </span>
                                  )}
                                  {/*<span className={'subscript text-muted'}>*/}
                                  {/*{item['latestLastPurchasedDate'] &&*/}
                                  {/*  '(Renewal Price)'}*/}
                                  {/*</span>*/}
                                </td>
                                <td>
                                  <button
                                    className={'btn-add-to-cart'}
                                    onClick={() =>
                                      isItemInCart
                                        ? this.handleRemoveFromCart(item)
                                        : this.handleAddToCart([item['@id']])
                                    }
                                  >
                                    {isItemInCart ? (
                                      <>
                                        <i
                                          className="fa fa-minus"
                                          aria-hidden="true"
                                        ></i>
                                        <span>Remove from Cart</span>
                                      </>
                                    ) : (
                                      <>
                                        <i
                                          className="fa fa-plus"
                                          aria-hidden="true"
                                        ></i>
                                        <span>Add to Cart</span>
                                      </>
                                    )}
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <div className={'purchasable-procedure-footer-buttons'}>
                      <div>
                        <button
                          className={'btn-action'}
                          id={'batch-add-to-cart'}
                          onClick={() =>
                            this.handleAddToCart(
                              this.state.batchAddPurchasableProceduresIds
                            )
                          }
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          <span>Add All to Cart</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={'no-results'}>
                    {this.isFiltered() ? (
                      // <p>No Procedure Templates match your filter.</p>
                      <p>No Templates match your filter.</p>
                    ) : (
                      <p>No Templates match your filter.</p>
                    )}
                  </div>
                )}
                {this.props.retrieved &&
                  this.props.retrieved['hydra:member'] &&
                  this.props.retrieved['hydra:member'].length > 0 &&
                  this.pagination()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  pagination() {
    const view = this.props.retrieved && this.props.retrieved['hydra:view'];
    if (!view) return;

    const {
      'hydra:first': first,
      'hydra:previous': previous,
      'hydra:next': next,
      'hydra:last': last
    } = view;

    if (!first && !previous && !next && !last) {
      return;
    }

    return (
      <nav aria-label="Page navigation">
        <Link
          to="."
          className={`btn btn-primary${previous ? '' : ' disabled'}`}
        >
          <span aria-hidden="true">&lArr;</span> First
        </Link>
        <Link
          to={
            !previous || previous === first
              ? '.'
              : encodeURIComponent(this.getPageWithFilterValues(previous))
          }
          className={`btn btn-primary${previous ? '' : ' disabled'}`}
        >
          <span aria-hidden="true">&larr;</span> Previous
        </Link>
        <Link
          to={
            next ? encodeURIComponent(this.getPageWithFilterValues(next)) : '#'
          }
          className={`btn btn-primary${next ? '' : ' disabled'}`}
        >
          Next <span aria-hidden="true">&rarr;</span>
        </Link>
        <Link
          to={
            last ? encodeURIComponent(this.getPageWithFilterValues(last)) : '#'
          }
          className={`btn btn-primary${next ? '' : ' disabled'}`}
        >
          Last <span aria-hidden="true">&rArr;</span>
        </Link>
      </nav>
    );
  }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource, deletedItem } =
    state.purchasableprocedure.list;
  const {
    retrieved: retrievedCart,
    loading: loadingCart,
    error: cartError,
    deletedItem: cartDeletedItem
  } = state.cart.list;
  return {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem,
    retrievedCart,
    loadingCart,
    cartError,
    cartDeletedItem,
    account: state.account
  };
};

const mapDispatchToProps = dispatch => ({
  changeFormField: (form, field, value) =>
    dispatch(changeFormField(form, field, value)),
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  cart: (page, account) => dispatch(cart(page, account)),
  createCart: item => dispatch(createCart(item)),
  createCartItem: item => dispatch(createCartItem(item)),
  deleteCartItem: item => dispatch(deleteCartItem(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
